var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "v-sidebar-menu",
      class: _vm.sidebarClass,
      style: [{ "max-width": _vm.sidebarWidth }],
      on: { mouseleave: _vm.onMouseLeave }
    },
    [
      _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _c(
            "v-card",
            { staticClass: "vertical-menu" },
            [
              _c(
                "v-tabs",
                {
                  attrs: { vertical: "" },
                  model: {
                    value: _vm.selectedTab,
                    callback: function($$v) {
                      _vm.selectedTab = $$v
                    },
                    expression: "selectedTab"
                  }
                },
                [
                  _vm._l(_vm.menuTabItem, function(item) {
                    return _c(
                      "v-tab",
                      {
                        key: item.tabIndex,
                        on: {
                          click: function($event) {
                            return _vm.getRoute(item.tabIndex, item.menuText)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "v-tab-text-alignment" }, [
                          _vm._v(" " + _vm._s(item.menuText) + " ")
                        ])
                      ]
                    )
                  }),
                  _c(
                    "v-tab-item",
                    { key: "ESL" },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _vm._t("header"),
                          _c("h1", { staticClass: "logo" }, [
                            _vm.MenuLogoImage === "" ||
                            _vm.MenuLogoImage === undefined ||
                            _vm.MenuLogoImage === null
                              ? _c("span", [
                                  _c("img", {
                                    staticClass: "logoclass",
                                    attrs: {
                                      src: require("@/assets/img/logo.svg"),
                                      alt: "solum esl dashboard"
                                    }
                                  })
                                ])
                              : _c("span", [
                                  _c("img", {
                                    staticStyle: {
                                      width: "183px",
                                      height: "50px"
                                    },
                                    attrs: {
                                      src: _vm.MenuLogoImage,
                                      alt: "solum esl dashboard"
                                    }
                                  })
                                ])
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-nav-fold",
                              attrs: { type: "button" }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/expandMenu-small.png"),
                                  alt: "열기"
                                }
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "vsm--scroll-wrapper",
                              class: { Expandlinkopened: _vm.isWebsiteLink },
                              style: _vm.isCollapsed && [
                                _vm.rtl
                                  ? { "margin-left": "-17px" }
                                  : { "margin-right": "-17px" }
                              ]
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "vsm--list",
                                  style: _vm.isCollapsed && {
                                    width: _vm.widthCollapsed
                                  }
                                },
                                [
                                  _c(
                                    "perfect-scrollbar",
                                    _vm._l(_vm.menu, function(item, index) {
                                      return _c(
                                        "sidebar-menu-item",
                                        {
                                          key: index,
                                          attrs: {
                                            item: item,
                                            "is-collapsed": _vm.isCollapsed,
                                            "active-show": _vm.activeShow,
                                            "show-one-child": _vm.showOneChild,
                                            "show-child": _vm.showChild,
                                            rtl: _vm.rtl,
                                            "mobile-item": _vm.mobileItem,
                                            "disable-hover": _vm.disableHover
                                          },
                                          on: {
                                            "set-mobile-item":
                                              _vm.setMobileItem,
                                            "unset-mobile-item":
                                              _vm.unsetMobileItem
                                          }
                                        },
                                        [
                                          _vm._t("dropdown-icon", null, {
                                            slot: "dropdown-icon"
                                          })
                                        ],
                                        2
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.isCollapsed
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "vsm--mobile-item",
                                      style: _vm.mobileItemStyle.item
                                    },
                                    [
                                      _vm.mobileItem
                                        ? _c(
                                            "sidebar-menu-item",
                                            {
                                              attrs: {
                                                item: _vm.mobileItem,
                                                "is-mobile-item": true,
                                                "mobile-item-style":
                                                  _vm.mobileItemStyle,
                                                "is-collapsed": _vm.isCollapsed,
                                                "show-child": _vm.showChild,
                                                rtl: _vm.rtl
                                              }
                                            },
                                            [
                                              _vm._t("dropdown-icon", null, {
                                                slot: "dropdown-icon"
                                              })
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _c(
                                        "transition",
                                        { attrs: { name: "slide-animation" } },
                                        [
                                          _vm.mobileItem
                                            ? _c("div", {
                                                staticClass: "vsm--mobile-bg",
                                                style:
                                                  _vm.mobileItemStyle.background
                                              })
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c("div", [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isWebsiteLink,
                                    expression: "isWebsiteLink"
                                  }
                                ],
                                staticClass: "expand-links"
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: " external-link-icon mb-3",
                                    on: {
                                      click: function($event) {
                                        return _vm.gotoSolumWebsite("website")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("SOLUM Website")) +
                                        " "
                                    ),
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/external-link.png"),
                                        alt: "solum website"
                                      }
                                    })
                                  ]
                                ),
                                _vm.layOutDesigner.used
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "external-link-icon mb-3",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoSolumfamilysiteLD(
                                              _vm.layOutDesigner.path
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("Layout Designer")) +
                                            " "
                                        ),
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/external-link.png"),
                                            alt: "Layout Designer"
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.webPDA.used
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: " external-link-icon mb-3",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoSolumfamilysitePDA(
                                              _vm.webPDA.path
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("Web PDA")) + " "
                                        ),
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/external-link.png"),
                                            alt: "PDA"
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.dashboardService.used &&
                                _vm.accessLevel === "1"
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: " external-link-icon mb-3",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoSolumfamilysiteSwaggerDasboardWeb(
                                              _vm.dashboardService.path
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "API Docs for DashboardService"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/external-link.png"),
                                            alt: "swagger"
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.portal.used && _vm.accessLevel === "1"
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: " external-link-icon mb-3",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoSolumfamilysiteSwaggerPortal(
                                              _vm.portal.path
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("API Docs for Portal")
                                            ) +
                                            " "
                                        ),
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/external-link.png"),
                                            alt: "swagger"
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "expand-title",
                                on: { click: _vm.showWebsiteLinks }
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "solum-family-link-title" },
                                  [
                                    _c(
                                      "span",
                                      { on: { click: _vm.getApiPath } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("SOLUM Family Site"))
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "23px" } },
                                      [
                                        _vm.isWebsiteLink
                                          ? _c("span", [_vm._v("-")])
                                          : _c(
                                              "span",
                                              { on: { click: _vm.getApiPath } },
                                              [_vm._v("+")]
                                            )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm.lcdTabEnable === "false" && _vm.lcdType === "GEN2"
                    ? _c(
                        "v-tab-item",
                        { key: "LCD" },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _vm._t("header"),
                              _c("h1", { staticClass: "logo" }, [
                                _vm.MenuLogoImage === "" ||
                                _vm.MenuLogoImage === undefined ||
                                _vm.MenuLogoImage === null
                                  ? _c("span", [
                                      _c("img", {
                                        staticClass: "logoclass",
                                        attrs: {
                                          src: require("@/assets/img/logo.svg"),
                                          alt: "solum esl dashboard"
                                        }
                                      })
                                    ])
                                  : _c("span", [
                                      _c("img", {
                                        staticStyle: {
                                          width: "183px",
                                          height: "50px"
                                        },
                                        attrs: {
                                          src: _vm.MenuLogoImage,
                                          alt: "solum esl dashboard"
                                        }
                                      })
                                    ])
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-nav-fold",
                                  attrs: { type: "button" }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/expandMenu-small.png"),
                                      alt: "열기"
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "vsm--scroll-wrapper",
                                  class: {
                                    Expandlinkopened: _vm.isWebsiteLink
                                  },
                                  style: _vm.isCollapsed && [
                                    _vm.rtl
                                      ? { "margin-left": "-17px" }
                                      : { "margin-right": "-17px" }
                                  ]
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vsm--list",
                                      style: _vm.isCollapsed && {
                                        width: _vm.widthCollapsed
                                      }
                                    },
                                    [
                                      _c(
                                        "perfect-scrollbar",
                                        _vm._l(_vm.lcdItem, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "sidebar-menu-item",
                                            {
                                              key: index,
                                              attrs: {
                                                item: item,
                                                "is-collapsed": _vm.isCollapsed,
                                                "active-show": _vm.activeShow,
                                                "show-one-child":
                                                  _vm.showOneChild,
                                                "show-child": _vm.showChild,
                                                rtl: _vm.rtl,
                                                "mobile-item": _vm.mobileItem,
                                                "disable-hover":
                                                  _vm.disableHover
                                              },
                                              on: {
                                                "set-mobile-item":
                                                  _vm.setMobileItem,
                                                "unset-mobile-item":
                                                  _vm.unsetMobileItem
                                              }
                                            },
                                            [
                                              _vm._t("dropdown-icon", null, {
                                                slot: "dropdown-icon"
                                              })
                                            ],
                                            2
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.isCollapsed
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "vsm--mobile-item",
                                          style: _vm.mobileItemStyle.item
                                        },
                                        [
                                          _vm.mobileItem
                                            ? _c(
                                                "sidebar-menu-item",
                                                {
                                                  attrs: {
                                                    item: _vm.mobileItem,
                                                    "is-mobile-item": true,
                                                    "mobile-item-style":
                                                      _vm.mobileItemStyle,
                                                    "is-collapsed":
                                                      _vm.isCollapsed,
                                                    "show-child": _vm.showChild,
                                                    rtl: _vm.rtl
                                                  }
                                                },
                                                [
                                                  _vm._t(
                                                    "dropdown-icon",
                                                    null,
                                                    { slot: "dropdown-icon" }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _c(
                                            "transition",
                                            {
                                              attrs: { name: "slide-animation" }
                                            },
                                            [
                                              _vm.mobileItem
                                                ? _c("div", {
                                                    staticClass:
                                                      "vsm--mobile-bg",
                                                    style:
                                                      _vm.mobileItemStyle
                                                        .background
                                                  })
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isWebsiteLink,
                                        expression: "isWebsiteLink"
                                      }
                                    ],
                                    staticClass: "expand-links"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: " external-link-icon mb-3",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoSolumWebsite(
                                              "website"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("SOLUM Website")) +
                                            " "
                                        ),
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/external-link.png"),
                                            alt: "solum website"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm.layOutDesigner.used
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "external-link-icon mb-3",
                                            on: {
                                              click: function($event) {
                                                return _vm.gotoSolumfamilysiteLD(
                                                  _vm.layOutDesigner.path
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("Layout Designer")
                                                ) +
                                                " "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/external-link.png"),
                                                alt: "Layout Designer"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.webPDA.used
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              " external-link-icon mb-3",
                                            on: {
                                              click: function($event) {
                                                return _vm.gotoSolumfamilysitePDA(
                                                  _vm.webPDA.path
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("Web PDA")) +
                                                " "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/external-link.png"),
                                                alt: "PDA"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.dashboardService.used &&
                                    _vm.accessLevel === "1"
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              " external-link-icon mb-3",
                                            on: {
                                              click: function($event) {
                                                return _vm.gotoSolumfamilysiteSwaggerDasboardWeb(
                                                  _vm.dashboardService.path
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "API Docs for DashboardService"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/external-link.png"),
                                                alt: "swagger"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.portal.used && _vm.accessLevel === "1"
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              " external-link-icon mb-3",
                                            on: {
                                              click: function($event) {
                                                return _vm.gotoSolumfamilysiteSwaggerPortal(
                                                  _vm.portal.path
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("API Docs for Portal")
                                                ) +
                                                " "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/external-link.png"),
                                                alt: "swagger"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "expand-title",
                                    on: { click: _vm.showWebsiteLinks }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "solum-family-link-title"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { on: { click: _vm.getApiPath } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("SOLUM Family Site")
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: { "font-size": "23px" }
                                          },
                                          [
                                            _vm.isWebsiteLink
                                              ? _c("span", [_vm._v("-")])
                                              : _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: _vm.getApiPath
                                                    }
                                                  },
                                                  [_vm._v("+")]
                                                )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]),
                              _vm._t("footer"),
                              !_vm.hideToggle
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "vsm--toggle-btn",
                                      class: {
                                        "vsm--toggle-btn_slot":
                                          _vm.$slots["toggle-icon"]
                                      },
                                      on: { click: _vm.onToggleClick }
                                    },
                                    [_vm._t("toggle-icon")],
                                    2
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.lcdTabEnable === "true" && _vm.lcdType === "GEN1"
                    ? _c(
                        "v-tab-item",
                        { key: "LCD" },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _vm._t("header"),
                              _c("h1", { staticClass: "logo" }, [
                                _vm.MenuLogoImage === "" ||
                                _vm.MenuLogoImage === undefined ||
                                _vm.MenuLogoImage === null
                                  ? _c("span", [
                                      _c("img", {
                                        staticClass: "logoclass",
                                        attrs: {
                                          src: require("@/assets/img/logo.svg"),
                                          alt: "solum esl dashboard"
                                        }
                                      })
                                    ])
                                  : _c("span", [
                                      _c("img", {
                                        staticStyle: {
                                          width: "183px",
                                          height: "50px"
                                        },
                                        attrs: {
                                          src: _vm.MenuLogoImage,
                                          alt: "solum esl dashboard"
                                        }
                                      })
                                    ])
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-nav-fold",
                                  attrs: { type: "button" }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/expandMenu-small.png"),
                                      alt: "열기"
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "vsm--scroll-wrapper",
                                  class: {
                                    Expandlinkopened: _vm.isWebsiteLink
                                  },
                                  style: _vm.isCollapsed && [
                                    _vm.rtl
                                      ? { "margin-left": "-17px" }
                                      : { "margin-right": "-17px" }
                                  ]
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vsm--list",
                                      style: _vm.isCollapsed && {
                                        width: _vm.widthCollapsed
                                      }
                                    },
                                    [
                                      _c(
                                        "perfect-scrollbar",
                                        _vm._l(_vm.lcdItem, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "sidebar-menu-item",
                                            {
                                              key: index,
                                              attrs: {
                                                item: item,
                                                "is-collapsed": _vm.isCollapsed,
                                                "active-show": _vm.activeShow,
                                                "show-one-child":
                                                  _vm.showOneChild,
                                                "show-child": _vm.showChild,
                                                rtl: _vm.rtl,
                                                "mobile-item": _vm.mobileItem,
                                                "disable-hover":
                                                  _vm.disableHover
                                              },
                                              on: {
                                                "set-mobile-item":
                                                  _vm.setMobileItem,
                                                "unset-mobile-item":
                                                  _vm.unsetMobileItem
                                              }
                                            },
                                            [
                                              _vm._t("dropdown-icon", null, {
                                                slot: "dropdown-icon"
                                              })
                                            ],
                                            2
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.isCollapsed
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "vsm--mobile-item",
                                          style: _vm.mobileItemStyle.item
                                        },
                                        [
                                          _vm.mobileItem
                                            ? _c(
                                                "sidebar-menu-item",
                                                {
                                                  attrs: {
                                                    item: _vm.mobileItem,
                                                    "is-mobile-item": true,
                                                    "mobile-item-style":
                                                      _vm.mobileItemStyle,
                                                    "is-collapsed":
                                                      _vm.isCollapsed,
                                                    "show-child": _vm.showChild,
                                                    rtl: _vm.rtl
                                                  }
                                                },
                                                [
                                                  _vm._t(
                                                    "dropdown-icon",
                                                    null,
                                                    { slot: "dropdown-icon" }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _c(
                                            "transition",
                                            {
                                              attrs: { name: "slide-animation" }
                                            },
                                            [
                                              _vm.mobileItem
                                                ? _c("div", {
                                                    staticClass:
                                                      "vsm--mobile-bg",
                                                    style:
                                                      _vm.mobileItemStyle
                                                        .background
                                                  })
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isWebsiteLink,
                                        expression: "isWebsiteLink"
                                      }
                                    ],
                                    staticClass: "expand-links"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: " external-link-icon mb-3",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoSolumWebsite(
                                              "website"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("SOLUM Website")) +
                                            " "
                                        ),
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/external-link.png"),
                                            alt: "solum website"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm.layOutDesigner.used
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "external-link-icon mb-3",
                                            on: {
                                              click: function($event) {
                                                return _vm.gotoSolumfamilysiteLD(
                                                  _vm.layOutDesigner.path
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("Layout Designer")
                                                ) +
                                                " "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/external-link.png"),
                                                alt: "Layout Designer"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.webPDA.used
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              " external-link-icon mb-3",
                                            on: {
                                              click: function($event) {
                                                return _vm.gotoSolumfamilysitePDA(
                                                  _vm.webPDA.path
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("Web PDA")) +
                                                " "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/external-link.png"),
                                                alt: "PDA"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.dashboardService.used &&
                                    _vm.accessLevel === "1"
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              " external-link-icon mb-3",
                                            on: {
                                              click: function($event) {
                                                return _vm.gotoSolumfamilysiteSwaggerDasboardWeb(
                                                  _vm.dashboardService.path
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "API Docs for DashboardService"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/external-link.png"),
                                                alt: "swagger"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.portal.used && _vm.accessLevel === "1"
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              " external-link-icon mb-3",
                                            on: {
                                              click: function($event) {
                                                return _vm.gotoSolumfamilysiteSwaggerPortal(
                                                  _vm.portal.path
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("API Docs for Portal")
                                                ) +
                                                " "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/external-link.png"),
                                                alt: "swagger"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "expand-title",
                                    on: { click: _vm.showWebsiteLinks }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "solum-family-link-title"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { on: { click: _vm.getApiPath } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("SOLUM Family Site")
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: { "font-size": "23px" }
                                          },
                                          [
                                            _vm.isWebsiteLink
                                              ? _c("span", [_vm._v("-")])
                                              : _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: _vm.getApiPath
                                                    }
                                                  },
                                                  [_vm._v("+")]
                                                )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]),
                              _vm._t("footer"),
                              !_vm.hideToggle
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "vsm--toggle-btn",
                                      class: {
                                        "vsm--toggle-btn_slot":
                                          _vm.$slots["toggle-icon"]
                                      },
                                      on: { click: _vm.onToggleClick }
                                    },
                                    [_vm._t("toggle-icon")],
                                    2
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-tab-item",
                    { key: "SYSTEM CONFIG" },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _vm._t("header"),
                          _c("h1", { staticClass: "logo" }, [
                            _vm.MenuLogoImage === "" ||
                            _vm.MenuLogoImage === undefined ||
                            _vm.MenuLogoImage === null
                              ? _c("span", [
                                  _c("img", {
                                    staticClass: "logoclass",
                                    attrs: {
                                      src: require("@/assets/img/logo.svg"),
                                      alt: "solum esl dashboard"
                                    }
                                  })
                                ])
                              : _c("span", [
                                  _c("img", {
                                    staticStyle: {
                                      width: "183px",
                                      height: "50px"
                                    },
                                    attrs: {
                                      src: _vm.MenuLogoImage,
                                      alt: "solum esl dashboard"
                                    }
                                  })
                                ])
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-nav-fold",
                              attrs: { type: "button" }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/expandMenu-small.png"),
                                  alt: "열기"
                                }
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "vsm--scroll-wrapper",
                              class: { Expandlinkopened: _vm.isWebsiteLink },
                              style: _vm.isCollapsed && [
                                _vm.rtl
                                  ? { "margin-left": "-17px" }
                                  : { "margin-right": "-17px" }
                              ]
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "vsm--list",
                                  style: _vm.isCollapsed && {
                                    width: _vm.widthCollapsed
                                  }
                                },
                                [
                                  _c(
                                    "perfect-scrollbar",
                                    _vm._l(_vm.SystemMenu, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "sidebar-menu-item",
                                        {
                                          key: index,
                                          attrs: {
                                            item: item,
                                            "is-collapsed": _vm.isCollapsed,
                                            "active-show": _vm.activeShow,
                                            "show-one-child": _vm.showOneChild,
                                            "show-child": _vm.showChild,
                                            rtl: _vm.rtl,
                                            "mobile-item": _vm.mobileItem,
                                            "disable-hover": _vm.disableHover
                                          },
                                          on: {
                                            "set-mobile-item":
                                              _vm.setMobileItem,
                                            "unset-mobile-item":
                                              _vm.unsetMobileItem
                                          }
                                        },
                                        [
                                          _vm._t("dropdown-icon", null, {
                                            slot: "dropdown-icon"
                                          })
                                        ],
                                        2
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.isCollapsed
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "vsm--mobile-item",
                                      style: _vm.mobileItemStyle.item
                                    },
                                    [
                                      _vm.mobileItem
                                        ? _c(
                                            "sidebar-menu-item",
                                            {
                                              attrs: {
                                                item: _vm.mobileItem,
                                                "is-mobile-item": true,
                                                "mobile-item-style":
                                                  _vm.mobileItemStyle,
                                                "is-collapsed": _vm.isCollapsed,
                                                "show-child": _vm.showChild,
                                                rtl: _vm.rtl
                                              }
                                            },
                                            [
                                              _vm._t("dropdown-icon", null, {
                                                slot: "dropdown-icon"
                                              })
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _c(
                                        "transition",
                                        { attrs: { name: "slide-animation" } },
                                        [
                                          _vm.mobileItem
                                            ? _c("div", {
                                                staticClass: "vsm--mobile-bg",
                                                style:
                                                  _vm.mobileItemStyle.background
                                              })
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isWebsiteLink,
                                  expression: "isWebsiteLink"
                                }
                              ],
                              staticClass: "expand-links"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: " external-link-icon mb-3",
                                  on: {
                                    click: function($event) {
                                      return _vm.gotoSolumWebsite("website")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("SOLUM Website")) + " "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/external-link.png"),
                                      alt: "solum website"
                                    }
                                  })
                                ]
                              ),
                              _vm.layOutDesigner.used
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "external-link-icon mb-3",
                                      on: {
                                        click: function($event) {
                                          return _vm.gotoSolumfamilysiteLD(
                                            _vm.layOutDesigner.path
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("Layout Designer")) +
                                          " "
                                      ),
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/img/external-link.png"),
                                          alt: "Layout Designer"
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm.webPDA.used
                                ? _c(
                                    "p",
                                    {
                                      staticClass: " external-link-icon mb-3",
                                      on: {
                                        click: function($event) {
                                          return _vm.gotoSolumfamilysitePDA(
                                            _vm.webPDA.path
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Web PDA")) + " "
                                      ),
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/img/external-link.png"),
                                          alt: "PDA"
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm.dashboardService.used &&
                              _vm.accessLevel === "1"
                                ? _c(
                                    "p",
                                    {
                                      staticClass: " external-link-icon mb-3",
                                      on: {
                                        click: function($event) {
                                          return _vm.gotoSolumfamilysiteSwaggerDasboardWeb(
                                            _vm.dashboardService.path
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "API Docs for DashboardService"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/img/external-link.png"),
                                          alt: "swagger"
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm.portal.used && _vm.accessLevel === "1"
                                ? _c(
                                    "p",
                                    {
                                      staticClass: " external-link-icon mb-3",
                                      on: {
                                        click: function($event) {
                                          return _vm.gotoSolumfamilysiteSwaggerPortal(
                                            _vm.portal.path
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("API Docs for Portal")
                                          ) +
                                          " "
                                      ),
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/img/external-link.png"),
                                          alt: "swagger"
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "expand-title",
                              on: { click: _vm.showWebsiteLinks }
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "solum-family-link-title" },
                                [
                                  _c(
                                    "span",
                                    { on: { click: _vm.getApiPath } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("SOLUM Family Site"))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "23px" } },
                                    [
                                      _vm.isWebsiteLink
                                        ? _c("span", [_vm._v("-")])
                                        : _c(
                                            "span",
                                            { on: { click: _vm.getApiPath } },
                                            [_vm._v("+")]
                                          )
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._t("footer"),
                          !_vm.hideToggle
                            ? _c(
                                "button",
                                {
                                  staticClass: "vsm--toggle-btn",
                                  class: {
                                    "vsm--toggle-btn_slot":
                                      _vm.$slots["toggle-icon"]
                                  },
                                  on: { click: _vm.onToggleClick }
                                },
                                [_vm._t("toggle-icon")],
                                2
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }