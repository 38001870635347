import AuthService from '@/auth/auth.service'
import commons from '@/plugins/commons'
import Cookies from 'js-cookie'
import configs from '@/plugins/configs'

export const auth = {
  namespaced: true,
  state: null,
  loginMode: null,
  loginState: false,
  tokenAPI: null,
  user: {
    account: '',
    accountGroup: '',
    accountInfo: {},
    aliveInterval: '',
    company: '',
    companyList: [],
    domain: '',
    firstName: '',
    loginMode: '',
    managedStores: [],
    portalRevision: '',
    responseCode: '',
    responseMessage: '',
    releaseVersion: '',
    coreRevision: '',
    tokenEmail: '',
    sessionId: '',
    sessionTimeout: 0,
    pwMinimumLength: 0,
    isSolumAdmin: false,
    pwPolicyEnable: false,
    lbsEnable: false,
    otaEnable: false,
    templateGroupEnable: false,
    addInfoConfigList: [],
    productIdExtraction: {},
    editableProductInfo: []
  },
  getters: {
    GET_USER (state) {
      if (commons.isValidStr(state.user?.account)) return state.user
    },
    GET_ACCOUNT (state) {
      if (commons.isValidStr(state.user?.account)) return state.user.account
    },
    GET_COMPANY: (state, getters, rootState) => {
      const selectedStore = rootState.dataStore.selectedStore
      if (commons.notEmpty(selectedStore)) {
        return selectedStore.company
      } else {
        return state.user?.company
      }
    },
    GET_INTERVAL: state => state.user?.aliveInterval,
    GET_USER_LEVEL: state => state.user?.accountInfo?.accessLevel,
    GET_ACC_MENU: state => state.user?.accountInfo?.accessMenu,
    GET_ACC_BTN: state => state.user?.accountInfo?.accessAction
  },
  mutations: {
    UPDATE_TOKEN: (state, value) => {
      state.tokenAPI = value
    },
    updateLoginMode (state, value) {
      state.loginMode = value
    },
    // for keycloak
    loginSuccess (state, user) {
      user.companyList = user.company.slice()
      user.company = user.company[0]
      if (!commons.isNull(user) && user !== '') state.user = user
      if (process.env.VUE_APP_PRODUCTION_TYPE === 'cloud') {
        if (!commons.isNull(state.tokenAPI)) {
          state.user.tokenEmail = state.tokenAPI.account.idToken.emails[0]
        }
      }
      if (configs.keycloakLogin === true) {
        if (!commons.isNull(state.tokenAPI)) {
          state.user.tokenEmail = state.tokenAPI
        }
      } else if (!commons.isNull(state.tokenAPI)) {
        state.user.tokenEmail = state.tokenAPI.account.idToken.emails[0]
      }
      // end
      if (!commons.isNull(state.user)) state.loginState = true
    },
    loginFailure (state) {
      state.status = {}
      state.user = {}
    },
    logout (state) {
      state.loginState = false
      state.loginMode = null
      state.tokenAPI = null
      state.user = {}
    },
    setCompany (state, company) {
      state.user.company = company
    },
    setProductIdExtraction (state, productIdExtractionList) {
      if (!state.user.productIdExtraction) {
        state.user.productIdExtraction = {
          list: [...productIdExtractionList]
        }
      }
      state.user.productIdExtraction.list = [...productIdExtractionList]
    },
    setProductIdExtractionEnable (state, productIdExtractionEnable) {
      state.user.productIdExtraction.enable = productIdExtractionEnable
    },
    setAddInfoConfigList (state, addInfoConfigList) {
      state.user.addInfoConfigList = [...addInfoConfigList]
    },
    setEditableProductInfo (state, editableProductInfo) {
      state.user.editableProductInfo = [...editableProductInfo]
    },
    setAccessMenu (state, accessMenu) {
      state.user.accountInfo.accessMenu = [...accessMenu]
    },
    setAccessAction (state, accessAction) {
      state.user.accountInfo.accessAction = [...accessAction]
    }
  },
  actions: {
    login ({ commit }, user) {
      commit('updateLoginMode', user.loginMode)
      return AuthService.loginAPI(user)
        .then(userData => {
          // For cookies
          Cookies.set('sessionId', userData.sessionId)
          delete userData.sessionId

          //
          commit('loginSuccess', userData)
          return userData
        })
        .catch(error => {
          throw error.response.data
        })
    },
    logout ({ commit }) {
      AuthService.logout()
      commit('logout')
    },
    isAccessMenu (context, code) {
      const flag = context.state.user.accountInfo.accessMenu.includes(code)
      return Promise.resolve(flag)
    },
    getDisabledBtn (context, code) {
      const flag = !context.rootState.auth.user.accountInfo.accessAction.includes(
        code
      )
      return Promise.resolve(flag)
    },
    UPDATE_TOKEN ({ commit }, payload) {
      commit('UPDATE_TOKEN', payload)
    },
    updateCompany (context, company) {
      context.commit('setCompany', company)
    },
    updateAddInfoConfigList (context, addInfoConfigList) {
      context.commit('setAddInfoConfigList', addInfoConfigList)
    },
    updateProductIdExtractionList (context, productIdExtractionList) {
      context.commit('setProductIdExtraction', productIdExtractionList)
    },
    updateProductIdExtractionEnable (context, productIdExtractionEnable) {
      context.commit('setProductIdExtractionEnable', productIdExtractionEnable)
    },
    updateEditableProductInfo (context, editableProductInfo) {
      context.commit('setEditableProductInfo', editableProductInfo)
    },
    updateAccessMenu (context, accessMenu) {
      context.commit('setAccessMenu', accessMenu)
    },
    updateAccessAction (context, accessAction) {
      context.commit('setAccessAction', accessAction)
    }
  }

}
