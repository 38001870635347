import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { checkApiSession } from '@/plugins/healthCheck'
import { clearTempObjs } from '@/plugins/sessionStorageManager'
import EventBus from '@/plugins/eventBus'
import lcdRoutes from '@/lcd/router/index'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: () =>
    import('@/views/Main'),
  meta: {
    requiresAuth: true
  },
  children: [{
    path: '/',
    name: 'Overview',
    component: () =>
      import('@/views/Overview')
  },
  {
    path: '/Label/LabelInfo',
    name: 'Label Info',
    component: () =>
      import('@/views/Label/LabelInfo')
  },
  {
    path: '/Label/FailRecodes',
    name: 'Fail Recodes',
    component: () =>
      import('@/views/Label/FailRecords')
  },
  {
    path: '/Label/Firmware',
    name: 'Firmware',
    component: () =>
      import('@/views/Label/Firmware')
  },
  {
    path: '/Label/LabelSettings',
    name: 'Label Config',
    component: () =>
      import('@/views/Label/LabelSettings')
  },
  {
    path: '/Product/ProductInfo',
    name: 'Product Info',
    component: () =>
      import('@/views/Product/ProductList')
  },
  {
    path: '/Product/UpdateRecords',
    name: 'Update Records',
    component: () =>
      import('@/views/Product/UpdateRecords')
  },
  {
    path: '/Gateway/GatewayInfo',
    name: 'Gateway Info',
    component: () =>
      import('@/views/Gateway/GatewayInfo')
  },
  {
    path: '/Gateway/GatewayInfoCloud',
    name: 'Gateway Info Cloud',
    component: () =>
      import('@/views/Gateway/GatewayInfoCloud')
  },
  {
    path: '/Gateway/GatewayFirmware',
    name: 'Gateway Firmware',
    component: () =>
      import('@/views/Gateway/GatewayFirmware')
  },
  {
    path: '/Template/TemplateTypeList',
    name: 'Template Info',
    component: () =>
      import('@/views/Template/TemplateTypeList')
  },
  {
    path: '/Template/ImageonTemplate',
    name: 'Image on Template',
    component: () =>
      import('@/views/Template/ImageonTemplate')
  },
  {
    path: '/Template/TemplateGrouping',
    name: 'Template Gouping',
    component: () =>
      import('@/views/Template/TemplateGrouping')
  },
  {
    path: '/Template/TemplateModelGrouping',
    name: 'Template Model Gouping',
    component: () =>
      import('@/views/Template/TemplateModelGrouping')
  },
  {
    path: '/Template/TemplateMapping',
    name: 'Template Mapping',
    component: () =>
      import('@/views/Template/TemplateMapping')
  },
  {
    path: '/Multifunction/LBS',
    name: 'LBS Info',
    component: () =>
      import('@/views/Multifunction/LBS')
  },
  {
    path: '/Multifunction/LBS_Shelf',
    name: 'LBS_Shelf Info',
    component: () =>
      import('@/views/Multifunction/LBS_Shelf')
  },
  {
    path: '/Multifunction/LED',
    name: 'LED Info',
    component: () =>
      import('@/views/Multifunction/LED')
  },
  {
    path: '/Store/StoreInfo',
    name: 'Store Info',
    component: () =>
      import('@/views/Store/StoreInfo')
  },
  {
    path: '/Store/WhiteList',
    name: 'White List',
    component: () =>
      import('@/views/Store/WhiteList')
  },
  {
    path: '/Reports/ErrorList',
    name: 'Error List',
    component: () =>
      import('@/views/Reports/ErrorList')
  },

  {
    path: '/Reports/ESLProductInfo',
    name: 'ESL Product Information',
    component: () =>
      import('@/views/Reports/ESLProductInfo')
  },
  {
    path: '/User/UserInfo',
    name: 'User Info',
    component: () =>
      import('@/views/User/UserInfo')
  },
  {
    path: '/User/UserInfoCloud',
    name: 'User Info Cloud',
    component: () =>
      import('@/views/User/UserInfoCloud')
  },
  {
    path: '/User/UserMapping',
    name: 'User Mapping',
    component: () =>
      import('@/views/User/UserMapping')
  },
  {
    path: '/User/UserMappingCloud',
    name: 'User Mapping Cloud',
    component: () =>
      import('@/views/User/UserMappingCloud')
  },
  {
    path: '/User/UserLevel',
    name: 'User Level',
    component: () =>
      import('@/views/User/UserLevel')
  },
  {
    path: '/User/MyAccount',
    name: 'My Account',
    component: () =>
      import('@/views/User/MyAccount')
  },
  {
    path: '/User/MyAccountCloud',
    name: 'My Account Cloud',
    component: () =>
      import('@/views/User/MyAccountCloud')
  },
  {
    path: '/System/DashboardConfigCloud',
    name: 'Dashboard Config',
    component: () =>
      import('@/views/System/DashboardConfigCloud')
  },
  {
    path: '/System/DashboardConfig',
    name: 'Dashboard Config Cloud',
    component: () =>
      import('@/views/System/DashboardConfig')
  },
  {
    path: '/System/HardwareSetting',
    name: 'Hardware Setting',
    component: () =>
      import('@/views/System/HardwareSetting')
  },
  // {
  //   path: '/System/ReportConfig',
  //   name: 'Mail Config',
  //   component: () =>
  //     import('@/views/System/ReportConfig')
  // },
  {
    path: '/System/AppConfig',
    name: 'App Config',
    component: () =>
      import('@/views/System/AppConfig')
  },
  {
    path: '/System/SystemLicense',
    name: 'System License',
    component: () =>
      import('@/views/System/SystemLicense')
  },
  {
    path: '/System/ProductFileConfig',
    name: 'Product File Config',
    component: () =>
      import('@/views/System/ProductFileConfig')
  },
  {
    path: '/Firmware/LabelFirmware',
    name: 'Label Firmware',
    component: () =>
      import('@/views/Firmware/LabelFirmware')
  },
  {
    path: '/Firmware/GatewayFirmware',
    name: 'Gateway Firmware',
    component: () =>
      import('@/views/Firmware/GatewayFirmware')
  },
  {
    path: '/Firmware/LabelOTA',
    name: 'Label OTA',
    component: () =>
      import('@/views/Firmware/LabelOTA')
  }
  ]
},
lcdRoutes,
{
  path: '/login',
  name: 'Login',
  component: () =>
    import('@/views/Login')
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 권한이 필요한 경로인 경우.
    // 로그인 여부 확인, 로그인 필요한 경우 로그인 페이지로 이동.
    // if (!store.state.auth.loginState) {
    //   next('/login')
    //   // EventBus.$emit('autoLogout')
    // }
    if (!store.state.auth.loginState) {
      // next('/login')
      // redirecting to login based on state
      // auto login feature
      next(`/login${to.fullPath.includes('?user') ? to.fullPath : ''}`)
      // EventBus.$emit('autoLogout')
    }
    // if (to.path.substring(0, 5) === '/Lcd/') { // Condition for LCD module
    //   next()
    if (to.path.substring(0, 7) === '/System') {
      store.dispatch('dataStore/updateSidebarMenu', 'SYSTEM_CONFIG')
      EventBus.$emit('TabActivate', 'SYSTEM_CONFIG')
      // this.$router.push('/System/DashboardConfig')
    } else if (to.path.substring(0, 5) === '/Lcd/') {
      store.dispatch('dataStore/updateSidebarMenu', 'LCD')
      EventBus.$emit('TabActivate', 'LCD')
    } else {
      EventBus.$emit('TabActivate', 'ESL')
      store.dispatch('dataStore/updateSidebarMenu', '')
    }
    // 세션 점검
    const apiSessionConnected = await checkApiSession()
    if (apiSessionConnected) {
      next()
    } else {
      EventBus.$emit('autoLogout')
      // await store.dispatch('auth/logout')
      // store.dispatch('dataStore/CLEAN')
      // next('/login')
    }
  } else {
    next()
  }
  if (from.fullPath !== '/') clearTempObjs()
  // if (from.fullPath !== process.env.VUE_PUBLIC_PATH) clearTempObjs()
})

export default router
