var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.icon.element ? _vm.icon.element : "i",
    _vm._b(
      {
        tag: "component",
        staticClass: "vsm--icon",
        class:
          typeof _vm.icon === "string" || _vm.icon instanceof String
            ? _vm.icon
            : _vm.icon.class
      },
      "component",
      _vm.icon.attributes,
      false
    ),
    [_vm._v(" " + _vm._s(_vm.icon.text) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }