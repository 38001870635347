var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.badge.element ? _vm.badge.element : "span",
    _vm._b(
      { tag: "component", staticClass: "vsm--badge", class: _vm.badge.class },
      "component",
      _vm.badge.attributes,
      false
    ),
    [_vm._v(" " + _vm._s(_vm.badge.text) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }