import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { auth } from './auth.module'
import { dataStore } from './dataStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: auth,
    dataStore: dataStore
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'dataStore']
    })
  ]
})
