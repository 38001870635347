// Apllication Type에 따라 불러온 환경변수를 이용해서 API 주소를 설정한다.
// 자세한 내용은 /README.md 참고.
import codes from '@/plugins/codes'

const config = {
  ApiUrl: process.env.VUE_APP_AIMS_SERVER_URL,
  ApiKey: process.env.VUE_APP_API_KEY,
  Release: process.env.VUE_APP_RELEASE,
  LcdUrl: process.env.VUE_APP_LCD_SERVER_URL
}
console.info('Default config' + JSON.stringify(config))

const getRuntimeConfig = async (productionType, baseUrl) => {
  let runtimeConfig = ''
  switch (productionType) {
    case 'local-server':
      runtimeConfig = await fetch(baseUrl + 'properties_local.json')
      break
    case 'test-server':
      runtimeConfig = await fetch(baseUrl + 'properties_test.json')
      break
    case 'cloud':
      runtimeConfig = await fetch(baseUrl + 'properties_cloud.json')
      break
    case 'server':
      runtimeConfig = await fetch(baseUrl + 'properties.json')
      break
    default:
      runtimeConfig = await fetch(baseUrl + 'properties.json')
      break
  }
  return await runtimeConfig.json()
}

const getServerIp = (connectionType = 'url', json) => {
  if (connectionType.toLowerCase() === 'url') return json.serverIp || codes.LOCALHOST
  return location.hostname
}

const init = () => {
  const productionType = process.env.VUE_APP_PRODUCTION_TYPE
  const connectionType = process.env.VUE_APP_CONNECTION_TYPE
  const baseUrl = process.env.BASE_URL
  if (process.env.VUE_APP_USING_PROPERTIES) {
    return getRuntimeConfig(productionType, baseUrl).then(function (json) {
      console.debug('Runtime config: ' + JSON.stringify(json))
      let protocol = ''
      let serverIp = ''
      let serverPort = ''
      let ServerAddress = ''
      let serverDomain = ''
      if (json.connectionType.toLowerCase() === 'url') {
        serverIp = window.location.hostname
        serverPort = window.location.port
        protocol = window.location.protocol
        serverDomain = json.serverDomain || '/'
        ServerAddress = protocol + '//' + serverIp + ':' + serverPort
      } else {
        protocol = json.protocolType || 'http'
        serverIp = getServerIp(connectionType, json)
        serverPort = json.serverPort || '9001'
        serverDomain = json.serverDomain || '/'
        ServerAddress = `${protocol}://${serverIp}:${serverPort}`
      }

      const newConfig = {
        ApiUrl: ServerAddress + (serverDomain || ''),
        ApiKey: json.apiKey,
        Release: json.Dashboard_Release,
        Version: json.version,
        customerInformation: json.customerInformation,
        lidlFunction: json.lidlFunction,
        favoriteButton: json.favoriteButton,
        Customer: json.customer,
        ServerAddress: ServerAddress,
        isLabelAlive: json.isLabelAlive,
        LcdUrl: json.lcdUrl,
        keycloakLogin: json.KeycloakLogin,
        keycloakurl: json.keycloakurl,
        keycloakrealm: json.keycloakrealm,
        keycloakclientId: json.keycloakclientId,
        keycloakonLoad: json.keycloakonLoad
      }
      Object.assign(config, newConfig)
      return newConfig
    })
  }
}

init()

export default config
