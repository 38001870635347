var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    _vm._b(
      {
        tag: "component",
        attrs: { tabindex: _vm.disabled ? -1 : 0, role: "link" },
        on: {
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.$parent.clickEvent($event)
          }
        }
      },
      "component",
      [
        _vm.tag === "router-link" ? { to: _vm.href } : { href: _vm.href },
        _vm.attributes
      ],
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }