const lcdChildrenRoutes = [
  {
    path: '/Lcd/Overview',
    name: 'Dicamo Overview',
    component: () => import('@/lcd/views/Overview')
  },
  {
    path: '/Lcd/pickcel/overview',
    name: 'Pixcel Overview',
    component: () => import('@/lcd/views/PixcelDashboard.vue')
  },
  {
    path: '/Lcd/Contents',
    name: 'Dicamo Contents',
    redirect: '/Lcd/Contents/MediaList'
  },
  {
    path: '/Lcd/Contents/MediaList',
    name: 'Dicamo Media List',
    component: () => import('@/lcd/views/Contents/Media')
  },
  {
    path: '/Lcd/Contents/ProductList',
    name: 'Dicamo Product List',
    component: () => import('@/lcd/views/Contents/Product')
  },
  {
    path: '/Lcd/Lcd',
    name: 'Dicamo LCD',
    redirect: '/Lcd/Lcd/LcdList'
  },
  {
    path: '/Lcd/Lcd/LcdList',
    name: 'DicamoLCDList',
    component: () => import('@/lcd/views/LCD/LCD'),
    props: true
  },
  {
    path: '/Lcd/Playlist',
    name: 'Dicamo Playlist Group',
    redirect: '/Lcd/Playlist/Playlist'
  },
  {
    path: '/Lcd/Playlist/Playlist',
    name: 'Dicamo Playlist Group List',
    component: () => import('@/lcd/views/Playlist/Playlist')
  },
  {
    path: '/Lcd/Playlist/NewPlaylistGroup',
    name: 'Dicamo New Playlist Group',
    component: () => import('@/lcd/views/Playlist/NewPlaylistGroup')
  },
  {
    path: '/Lcd/Playlist/EditPlaylistGroup',
    name: 'Dicamo Edit Playlist Group',
    component: () => import('@/lcd/views/Playlist/EditPlaylistGroup'),
    props: true
  },
  {
    path: '/Lcd/Template',
    name: 'Dicamo Template',
    redirect: '/Lcd/Template/TemplateList'
  },
  {
    path: '/Lcd/Template/TemplateList',
    name: 'Dicamo Template List',
    component: () => import('@/lcd/views/Template/Template')
  },
  {
    path: '/Lcd/Template/NewTemplateList',
    name: 'Dicamo Template Add',
    component: () => import('@/lcd/views/Template/NewTemplate')
  },
  {
    path: '/Lcd/Template/EditTemplateList',
    name: 'Dicamo Template Edit',
    component: () => import('@/lcd/views/Template/EditTemplate'),
    props: true
  },
  {
    path: '/Lcd/Template/TemplateMappingList',
    name: 'Dicamo Template Mapping',
    component: () => import('@/lcd/views/Template/TemplateMapping')
  },
  {
    path: '/Lcd/Setting',
    name: 'Setting',
    component: () => import('@/lcd/views/Setting')
  }

]

const lcdRoutes = {
  path: '/Lcd',
  name: 'LCD Main',
  component: () => import('@/views/Main'),
  redirect: '/Lcd/Overview',
  meta: {
    requiresAuth: true
  },
  children: lcdChildrenRoutes
}
export default lcdRoutes
