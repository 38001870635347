<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null
  })
}
</script>
<style>.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){  background: #E2E2E2 !important;}.v-tabs-slider-wrapper{  height: 0px !important;}.v-tab {    letter-spacing :0!important;;}.v-tab--active{  font-weight: bolder !important;}</style>
